export var DocumentStatus;
(function (DocumentStatus) {
    DocumentStatus[DocumentStatus["PENDING"] = 1] = "PENDING";
    DocumentStatus[DocumentStatus["TO_BE_SIGNED"] = 2] = "TO_BE_SIGNED";
    DocumentStatus[DocumentStatus["SIGNED"] = 3] = "SIGNED";
    DocumentStatus[DocumentStatus["MANUAL_UPLOAD"] = 4] = "MANUAL_UPLOAD";
    DocumentStatus[DocumentStatus["CANCELLED"] = 10] = "CANCELLED";
})(DocumentStatus || (DocumentStatus = {}));
export var ColumnDataType;
(function (ColumnDataType) {
    ColumnDataType[ColumnDataType["TEXT"] = 1] = "TEXT";
    ColumnDataType[ColumnDataType["NUMBER"] = 2] = "NUMBER";
    ColumnDataType[ColumnDataType["DATE"] = 3] = "DATE";
    ColumnDataType[ColumnDataType["DATETIME"] = 4] = "DATETIME";
    ColumnDataType[ColumnDataType["WAGE_AMOUNT"] = 5] = "WAGE_AMOUNT";
    ColumnDataType[ColumnDataType["PERCENTAGE"] = 6] = "PERCENTAGE";
    ColumnDataType[ColumnDataType["TIME"] = 7] = "TIME";
    ColumnDataType[ColumnDataType["HIDDEN"] = 8] = "HIDDEN";
})(ColumnDataType || (ColumnDataType = {}));
export var SignatureStatus;
(function (SignatureStatus) {
    SignatureStatus[SignatureStatus["VALID"] = 1] = "VALID";
    SignatureStatus[SignatureStatus["INVALID"] = 2] = "INVALID";
    SignatureStatus[SignatureStatus["PENDING_REVIEW"] = 3] = "PENDING_REVIEW";
    SignatureStatus[SignatureStatus["EMPTY"] = 4] = "EMPTY";
})(SignatureStatus || (SignatureStatus = {}));
export var Language;
(function (Language) {
    Language["nl"] = "nl";
    Language["fr"] = "fr";
    Language["en"] = "en";
})(Language || (Language = {}));
export var ToastTypes;
(function (ToastTypes) {
    ToastTypes[ToastTypes["INFO"] = 0] = "INFO";
    ToastTypes[ToastTypes["ERROR"] = 1] = "ERROR";
    ToastTypes[ToastTypes["SUCCESS"] = 2] = "SUCCESS";
    ToastTypes[ToastTypes["WARNING"] = 3] = "WARNING";
})(ToastTypes || (ToastTypes = {}));
export var AjaxMethod;
(function (AjaxMethod) {
    AjaxMethod["Get"] = "GET";
    AjaxMethod["Post"] = "POST";
    AjaxMethod["Delete"] = "DELETE";
})(AjaxMethod || (AjaxMethod = {}));
export var EmployeeStatus;
(function (EmployeeStatus) {
    EmployeeStatus[EmployeeStatus["INACTIVE"] = 0] = "INACTIVE";
    EmployeeStatus[EmployeeStatus["ACTIVE"] = 1] = "ACTIVE";
    EmployeeStatus[EmployeeStatus["OUT_OF_DUTY"] = 2] = "OUT_OF_DUTY";
    EmployeeStatus[EmployeeStatus["DEATH"] = 3] = "DEATH";
    EmployeeStatus[EmployeeStatus["ACTIVE_CANDIDATE"] = 51] = "ACTIVE_CANDIDATE";
})(EmployeeStatus || (EmployeeStatus = {}));
export var AdvanceTypes;
(function (AdvanceTypes) {
    AdvanceTypes["MONTH"] = "maandafsluiting";
    AdvanceTypes["WEEK"] = "week";
})(AdvanceTypes || (AdvanceTypes = {}));
export var DisplayStatus;
(function (DisplayStatus) {
    DisplayStatus["SUCCESS"] = "success";
    DisplayStatus["ERROR"] = "danger";
    DisplayStatus["INFO"] = "info";
})(DisplayStatus || (DisplayStatus = {}));
export var PlanningValidationStatus;
(function (PlanningValidationStatus) {
    PlanningValidationStatus["OK"] = "ok";
    PlanningValidationStatus["FAILED"] = "failed";
})(PlanningValidationStatus || (PlanningValidationStatus = {}));
