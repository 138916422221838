export class Helpers {
    static getTextBetweenDelimiters(subject, frontDelimiter, endDelimiter) {
        return subject.split(frontDelimiter).pop().split(endDelimiter)[0];
    }
    static getQueryParam(name) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(name);
    }
    static merge(a, b) {
        return { ...a, ...b };
    }
    static range(start, end) {
        return Array.from({ length: end - start + 1 }, (v, k) => k + start);
    }
}
