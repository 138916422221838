import { ToastTypes } from "./Enums";
import { Helpers } from "./Helpers";
export class Toastr {
    static toastDefaults = {
        title: "Staffweb",
        message: undefined,
        shortcutFunction: "info",
        closeButton: true,
        progressBar: true,
        positionClass: "toast-bottom-right",
        onclick: null,
        showDuration: 500,
        hideDuration: 500,
        timeOut: 3000,
        extendedTimeOut: 3000,
    };
    static toastBase(options) {
        options = Helpers.merge(this.toastDefaults, options);
        toastr.options = {
            closeButton: options.closeButton,
            progressBar: options.progressBar,
            positionClass: options.positionClass,
            onclick: options.onclick,
            showDuration: options.showDuration,
            hideDuration: options.hideDuration,
            timeOut: options.timeOut,
            extendedTimeOut: options.extendedTimeOut,
        };
        // Fix for possible HTML code in the message
        options.message = $("<div/>").html(options.message).html();
        // @ts-ignore
        let $toast = toastr[options.shortcutFunction](options.message, options.title);
    }
    static toast(type, title, message, options) {
        let toastoptions = {
            title: title,
            message: message,
        };
        if (options != null)
            $.extend(toastoptions, options);
        switch (type) {
            case ToastTypes.INFO:
                $.extend(toastoptions, { shortcutFunction: "info" });
                break;
            case ToastTypes.SUCCESS:
                $.extend(toastoptions, { shortcutFunction: "success" });
                break;
            case ToastTypes.WARNING:
                $.extend(toastoptions, { shortcutFunction: "warning" });
                break;
            case ToastTypes.ERROR:
                $.extend(toastoptions, {
                    shortcutFunction: "error",
                    timeOut: 5000,
                    extendedTimeOut: 5000,
                });
                break;
        }
        this.toastBase(toastoptions);
    }
}
